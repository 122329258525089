import React, { useState } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciFileUploadHistoryKeys from '../../../constants/locale/key/SbciFileUploadHistory'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import FileUploadHistoryApiUrls from '../../../apiUrls/FileUploadHistoryApiUrls'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import SbciProductMasterApiUrls from '../../../apiUrls/SbciProductMasterApiUrls'
import {
  FileForUserGetPartyId,
  FileForUserGetUserDetails
} from '../../../common/FileForUserCommon'
import Table from '../../../components/aciacehighway/Table'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Box,
  Chip,
  Grid,
  Link,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  form: {
    field: { CngSelectField }
  }
} = components

const {
  filter: { BETWEEN, EQUAL, LIKE }
} = constants

function TablePage(props) {
  const { showNotification } = props
  const { securedSendRequest } = useServices()
  const theme = useTheme()

  const { translate } = useTranslation([Namespace.SBCI_FILE_UPLOAD_HISTORY, Namespace.SBCI_INVOICE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let id = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.ID
    )
    let fileType = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.FILE_TYPE
    )
    let processStatus = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.PROCESS_STATUS
    )
    let receivedDate = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.RECEIVED_DATE
    )
    let receivedFileName = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.RECEIVED_FILE_NAME
    )
    let acknowledgedFileName = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.ACKNOWLEDGED_FILE_NAME
    )
    let errorMessage = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.ERROR_MESSAGE
    )

    let csvMaxFileSize = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MAX_FILE_SIZE
    )

    let csvInvalidFileType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_INVALID_FILE_TYPE
    )

    let csvFileType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_TYPE
    )

    let csvInvalidFile = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_INVALID_FILE
    )

    let csvMessageType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE
    )

    let csvSelectMessageType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_SELECT_MESSAGE_TYPE
    )

    let csvMessageTypeInv = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE_INV
    )

    let csvMessageTypePm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE_PM
    )

    let csvFileUploadSuccess = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_UPLOAD_SUCCESS
    )

    let csvFileUploadError = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_UPLOAD_ERROR
    )

    let uploadButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.UPLOAD_BUTTON
    )

    let csvFileDetails = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_DETAILS
    )

    let csvFileName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_NAME
    )

    let csvFileLastModified = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_FILE_LAST_MODIFIED
    )

    let csvSelectFile = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_SELECT_FILE
    )

    let csvTitle = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_TITLE
    )

    return {
      id,
      fileType,
      processStatus,
      receivedDate,
      receivedFileName,
      acknowledgedFileName,
      errorMessage,
      csvMaxFileSize,
      csvInvalidFileType,
      csvFileType,
      csvInvalidFile,
      csvMessageType,
      csvSelectMessageType,
      csvMessageTypeInv,
      csvMessageTypePm,
      csvFileUploadSuccess,
      csvFileUploadError,
      uploadButton,
      csvFileDetails,
      csvFileName,
      csvFileLastModified,
      csvSelectFile,
      csvTitle
    }
  }

  const columns = [
    {
      field: 'id',
      sortKey: 'id',
      title: translatedTextsObject.id
    },
    {
      field: 'fileType',
      sortKey: 'fileType',
      title: translatedTextsObject.fileType,
      render: (rowData) =>
        rowData.fileType && (
          <Typography variant='inherit'>
            {rowData.fileType === 'INV'
              ? 'Invoice'
              : rowData.fileType === 'PM' && 'Product Master'}
          </Typography>
        )
    },
    {
      field: 'processStatus',
      sortKey: 'processStatus',
      title: translatedTextsObject.processStatus,
      render: (rowData) => {
        if (!rowData.processStatus) return

        const processStatus = rowData.processStatus.toLowerCase()

        return processStatus === 'failed' ? (
          <Chip
            label='Failed'
            size='small'
            style={{
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText
            }}
          />
        ) : (
          processStatus === 'success' && (
            <Chip
              label='Success'
              size='small'
              style={{
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText
              }}
            />
          )
        )
      }
    },
    {
      field: 'receivedFileName',
      title: translatedTextsObject.receivedFileName,
      render: (rowData) => (
        <Tooltip title={rowData.receivedFileName}>
          <Box maxWidth={150}>
            <Link
              component='div'
              noWrap
              onClick={() =>
                onDownloadFile(rowData.receivedFileName, 'upload_file', rowData.fileType)
              }
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {rowData.receivedFileName}
            </Link>
          </Box>
        </Tooltip>
      )
    },
    {
      field: 'acknowledgedFileName',
      title: translatedTextsObject.acknowledgedFileName,
      render: (rowData) => (
        <Tooltip title={rowData.acknowledgedFileName}>
          <Box maxWidth={150}>
            <Link
              component='div'
              noWrap
              onClick={() =>
                onDownloadFile(rowData.acknowledgedFileName, 'ark_file', rowData.fileType)
              }
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {rowData.acknowledgedFileName}
            </Link>
          </Box>
        </Tooltip>
      )
    },
    {
      field: 'receivedDate',
      title: translatedTextsObject.receivedDate,
      sortKey: 'receivedDate',
      render: (rowData) =>
        rowData.receivedDate &&
        moment(rowData.receivedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'errorMessage',
      title: translatedTextsObject.errorMessage,
      render: (rowData) => <Box minWidth={300}>{rowData.errorMessage}</Box>
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.fileType,
      type: 'radio',
      name: 'fileType',
      operatorType: LIKE,
      options: [
        {
          label: 'Invoice',
          value: 'INV',
          filterValue: {
            value: 'INV'
          }
        },
        {
          label: 'Product Master',
          value: 'PM',
          filterValue: {
            value: 'PM'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.processStatus,
      type: 'radio',
      name: 'processStatus',
      operatorType: LIKE,
      options: [
        {
          label: 'Success',
          value: 'success',
          filterValue: {
            value: 'success'
          }
        },
        {
          label: 'Failed',
          value: 'failed',
          filterValue: {
            value: 'failed'
          }
        }
      ]
    },
    {
      label: 'Received Date',
      type: 'daterange',
      name: 'receivedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.rejErrMsg,
      type: 'textfield',
      name: 'rejErrMsg',
      operatorType: LIKE
    }
  ]

  function onDownloadFile(filename, fileType, source) {
    const url = FileUploadHistoryApiUrls.DOWNLOAD

    securedSendRequest.execute(
      'POST',
      url + '?filename=' + filename + '&type=' + fileType + '&module=' + source,
      { responseType: 'blob' },
      (response) => {
        let blob = new Blob([response.data]),
          downloadUrl = window.URL.createObjectURL(blob),
          disposition = response.headers['content-disposition']
  
        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition)
  
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
  
        let a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      },
      () => showNotification('error', 'File not found.'),
    )
  }

  function handleUploadFile(data) {
    const { fileType } = data
    const { file } = data.file != null && data.file.length > 0 ? data.file[0] : null 

    if (!_.isEmpty(file)) {
      if (file.size > 6291456) {
        showNotification('error', translatedTextsObject.csvMaxFileSize)
        return
      }

      if (file.type != 'text/csv') {
        showNotification('error', translatedTextsObject.csvInvalidFileType)
        return
      }
    } else {
      showNotification('error', translatedTextsObject.csvInvalidFile)
      return
    }

    const fileForUserDetails = FileForUserGetUserDetails()
    const formData = new FormData()
    const url =
      fileType === 'INV'
        ? SbciInvoiceApiUrls.UPLOAD
        : fileType === 'PM'
        ? SbciProductMasterApiUrls.UPLOAD
        : ''
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    formData.append('fileForUserDetails', fileForUserDetails ? JSON.stringify(fileForUserDetails) : '{}')
    formData.append('file', file)

    if (fileType === 'INV' || fileType === 'PM') {
      securedSendRequest.execute('POST', url, formData,
        () => showNotification('success', translatedTextsObject.csvFileUploadSuccess),
        () => showNotification('error', translatedTextsObject.csvFileUploadError),
        () => {},
        config
      )
    } else {
      showNotification('error', translatedTextsObject.csvSelectMessageType)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FileUploadSection
          onUpload={handleUploadFile}
          showNotification={showNotification}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={columns}
          compact
          exportData={{ url: FileUploadHistoryApiUrls.EXPORT }}
          fetch={{ url: FileUploadHistoryApiUrls.GET }}
          fetchFilters={[
            { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
          ]}
          filters={filters}
          rowActions={[
            {
              icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
              label: 'Export received file',
              onClick: (rowData) =>
                onDownloadFile(rowData.receivedFileName, 'upload_file', rowData.fileType)
            },
            {
              icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
              label: 'Export acknowledged file',
              onClick: (rowData) =>
                onDownloadFile(rowData.acknowledgedFileName, 'ark_file', rowData.fileType)
            }
          ]}
          showNotification={showNotification}
          sortConfig={{
            type: 'column',
            defaultField: 'id',
            defaultDirection: 'DESC'
          }}
        />
      </Grid>
    </Grid>
  )
}

function FileUploadSection(props) {
  const { onUpload, showNotification } = props

  const methods = useForm({
    defaultValues: { fileType: 'INV', file: [] }
  })
  const file = methods.watch('file')

  const [loading, setLoading] = useState(false)

  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const csvMessageTypeInv = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE_INV
    )
    const csvMessageTypePm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CSV_MESSAGE_TYPE_PM
    )

    return { csvMessageTypeInv, csvMessageTypePm }
  }

  function onSubmit(data) {
    if (_.isEmpty(file)) return

    setLoading(true)
    onUpload(data)
    methods.setValue('file', [])
    setLoading(false)
  }

  return (
    <Paper>
      <Box padding={2}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <CngSelectField
                      name='fileType'
                      label='File type'
                      items={[
                        { text: translatedTextsObject.csvMessageTypeInv, value: 'INV' },
                        { text: translatedTextsObject.csvMessageTypePm, value: 'PM' }
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CngFileUpload
                  accept={['.csv']}
                  maxFiles={1}
                  maxSize={6291456}
                  files={methods.getValues('file')}
                  onFileSelect={(file) => methods.setValue('file', file)}
                  showFormFields={false}
                  onDropRejected={(message) =>
                    showNotification('error', message)
                  }
                  moreActions={[
                    {
                      action: 'remove',
                      name: 'Remove',
                      icon: ['fal', 'trash']
                    }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='flex-end'>
                  <Grid item xs='auto'>
                    <CngButton type='submit' disabled={!file || file.length == 0 || loading} >Upload</CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Paper>
  )
}

export default TablePage
