import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ProductMasterKeys from 'src/constants/locale/key/ProductMaster'
import Grid from '@material-ui/core/Grid'
import UndgAutoCompleteMasterField from 'src/views/productmaster/UndgAutoCompleteMasterField.js'
import { useFormContext } from 'react-hook-form'

const { CngGridItem } = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  undgDropDown: '',
  undgId: '',
  code: '',
  description: '',
  intLCode: '',
  language: '',
  indicator: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue } = useFormContext()
  const { translate } = useTranslation(Namespace.PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const code = translate(Namespace.PRODUCT_MASTER, ProductMasterKeys.CODE)

    return { code }
  }

  return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.undgDropDown}>
        <UndgAutoCompleteMasterField
          name='code'
          label={translatedTextsObject.code}
          disabled={disabled}
          onChange={(_, options) => {
            if (options !== null) {
              setValue('code', options.data.intLCode || '')
            } else {
              setValue('code', '')
            }
          }}
        />
      </CngGridItem>
    </Grid>
  )
}

const UndgsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default UndgsFormProperties
