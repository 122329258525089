import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciFileUploadHistoryKeys from 'src/constants/locale/key/SbciFileUploadHistory'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: 0,
  fileType: "",
  processStatus: "",
  receivedDate: "",
  receivedFileName: "",
  acknowledgedFileName: "",
  errorMessage: "",
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
  version: 0,
  partyId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.SBCI_FILE_UPLOAD_HISTORY)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let SbciFileUploadHistory = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.TITLE
    )
    let id = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.ID
    )
    let fileType = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.FILE_TYPE
    )
    let processStatus = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.PROCESS_STATUS
    )
    let receivedDate = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.RECEIVED_DATE
    )
    let receivedFileName = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.RECEIVED_FILE_NAME
    )
    let acknowledgedFileName = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.ACKNOWLEDGED_FILE_NAME
    )
    let errorMessage = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.ERROR_MESSAGE
    )
    let createdBy = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.VERSION
    )
    let partyId = translate(
      Namespace.SBCI_FILE_UPLOAD_HISTORY,
      SbciFileUploadHistoryKeys.PARTY_ID
    )

    return {
      SbciFileUploadHistory,
      id,
      fileType,
      processStatus,
      receivedDate,
      receivedFileName,
      acknowledgedFileName,
      errorMessage,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      partyId
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.SbciFileUploadHistory} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
            <CngTextField
              name="id"
              type="number"
              label={translatedTextsObject.id + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileType}>
            <CngTextField
              name="fileType"
              label={translatedTextsObject.fileType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processStatus}>
            <CngTextField
              name="processStatus"
              label={translatedTextsObject.processStatus}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receivedDate}>
            <CngDateField
              name="receivedDate"
              label={translatedTextsObject.receivedDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receivedFileName}>
            <CngTextField
              name="receivedFileName"
              label={translatedTextsObject.receivedFileName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.acknowledgedFileName}>
            <CngTextField
              name="acknowledgedFileName"
              label={translatedTextsObject.acknowledgedFileName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.errorMessage}>
            <CngTextField
              name="errorMessage"
              label={translatedTextsObject.errorMessage}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdBy}>
            <CngTextField
              name="createdBy"
              type="number"
              label={translatedTextsObject.createdBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdDate}>
            <CngDateField
              name="createdDate"
              label={translatedTextsObject.createdDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedBy}>
            <CngTextField
              name="updatedBy"
              type="number"
              label={translatedTextsObject.updatedBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedDate}>
            <CngDateField
              name="updatedDate"
              label={translatedTextsObject.updatedDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.version}>
            <CngTextField
              name="version"
              type="number"
              label={translatedTextsObject.version}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
            <CngTextField
              name="partyId"
              type="number"
              label={translatedTextsObject.partyId}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.receivedDate = DateTimeFormatter.toClientDate(
    localData.receivedDate
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.receivedDate = DateTimeFormatter.toServerDate(
    localData.receivedDate
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
