import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useServices } from 'cng-web-lib'
import ProductMasterApiUrls from 'src/apiUrls/ProductMasterApiUrls'
const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField
    },
  },
  CngGridItem,
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function UndgAutoCompleteMasterField(props, codeMaintenanceType) {

  const {createRecord} = useServices()
  const history = useHistory()
  var codeMType = ""

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngLookupAutocompleteField
      {...lookupAutocompleteProps}
      lookupProps={{
        url: ProductMasterApiUrls.UNDG_SEARCH,
        label: (record)=> {
          return `${record.intLCode};${record.descriptionEn.trim()}`
        },
        value: 'intLCode',
        filters: [
          // {
          //   field: 'undgId',
          //   operator: 'equal',
          //   value: props.status == undefined ? 'DR' : props.status
          // },
          ...filters
        ],
        ...otherLookupProps
      }}
    />
  )
}

UndgAutoCompleteMasterField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default UndgAutoCompleteMasterField
