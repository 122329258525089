import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    id: Yup.number().required(requiredMessage),
    fileType: Yup.string(),
    processStatus: Yup.string(),
    receivedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    receivedFileName: Yup.string(),
    acknowledgedFileName: Yup.string(),
    errorMessage: Yup.string(),
    createdBy: Yup.number(),
    createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    updatedBy: Yup.number(),
    updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    version: Yup.number(),
    partyId: Yup.number(),
  })
}

export default makeValidationSchema
