import React, { useState, useRef } from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import { Chip, useTheme } from '@material-ui/core'
import { generatePath } from 'react-router-dom'
import pathMap from '../../paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import ProductMasterKeys from 'src/constants/locale/key/ProductMaster'
import ProductMasterApiUrls from 'src/apiUrls/ProductMasterApiUrls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../components/aciacehighway/Table'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import { FileForUserGetPartyId } from '../../common/FileForUserCommon'

const {
  filter: { EQUAL, LIKE }
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const [confirmDialog, setConfirmDialog] = useState({ open: false, product: null })
  const tableRef = useRef()
  const theme = useTheme()

  const { deleteRecord } = useServices()
  const { translate } = useTranslation(Namespace.PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let productidn = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCTIDN
    )
    let productDesc1 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC1
    )
    let productDesc2 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC2
    )
    let productDesc3 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC3
    )
    let productDesc4 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC4
    )
    let productDesc5 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC5
    )
    let productDesc6 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC6
    )
    let productDesc7 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC7
    )
    let productDesc8 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC8
    )
    let productDesc9 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC9
    )
    let hsCode = translate(Namespace.PRODUCT_MASTER, ProductMasterKeys.HS_CODE)
    let hsCodeDesc = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.HS_CODE_DESC
    )
    let countryOfOrigin = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.COUNTRY_OF_ORIGIN
    )
    let active = translate(Namespace.PRODUCT_MASTER, ProductMasterKeys.ACTIVE)
    let marksAndNumbers = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.MARKS_AND_NUMBERS
    )

    return {
      productidn,
      productDesc1,
      productDesc2,
      productDesc3,
      productDesc4,
      productDesc5,
      productDesc6,
      productDesc7,
      productDesc8,
      productDesc9,
      hsCode,
      hsCodeDesc,
      countryOfOrigin,
      active,
      marksAndNumbers
    }
  }

  const columns = [
    {
      field: 'productidn',
      title: translatedTextsObject.productidn
    },
    {
      field: 'productDesc1',
      title: translatedTextsObject.productDesc1
    },
    {
      field: 'active',
      title: translatedTextsObject.active,
      render: (data) =>
        data.active ? (
          <Chip
            label='Active'
            size='small'
            style={{
              backgroundColor: theme.palette.success.main,
              color: theme.palette.success.contrastText
            }}
          />
        ) : (
          <Chip label='Inactive' size='small' />
        )
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.productidn,
      type: 'textfield',
      name: 'productidn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.productDesc1,
      type: 'textfield',
      name: 'productDesc1',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.active,
      type: 'radio',
      name: 'active',
      operatorType: EQUAL,
      options: [
        {
          label: 'Active',
          value: 'true',
          filterValue: {
            value: 'true'
          }
        },
        {
          label: 'Inactive',
          value: 'false',
          filterValue: {
            value: 'false'
          }
        }
      ]
    }
  ]

  function handleDeleteProduct() {
    if (confirmDialog.product) {
      deleteRecord.execute(ProductMasterApiUrls.DELETE, confirmDialog.product,
        () => {
          showNotification('success', 'Product deleted successfully.')
          setConfirmDialog({ open: false, product: null })

          if (tableRef.current.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.PRODUCT_MASTER_ADD_VIEW)
            },
            label: 'Create Product Master'
          }
        ]}
        columns={columns}
        compact
        fetch={{ url: ProductMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(generatePath(pathMap.PRODUCT_MASTER_EDIT_VIEW, { id: rowData.id }))
        }
        rowActions={[
          {
            label: 'Edit',
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.PRODUCT_MASTER_EDIT_VIEW, { id: rowData.id })
              )
          },
          {
            label: 'Delete',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, product: rowData })
          }
        ]}
        showNotification={showNotification}
        persistSettings
        tableRef={tableRef}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, product: null })}
        onCancel={() => setConfirmDialog({ open: false, product: null })}
        onConfirm={handleDeleteProduct}
        title='Discard'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
