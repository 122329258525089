import React , { useState, useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ProductMasterKeys from 'src/constants/locale/key/ProductMaster'
import { Card, Grid, Typography } from '@material-ui/core'
import { NaHSCodeAutocompleteField } from 'src/components/na'
import { components, DataFlattener, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import UndgsFormProperties from './UndgsFormProperties'
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import { useFormContext } from 'react-hook-form'
import CngSection from '../../components/cngcomponents/CngSection'
import LocalTable from '../../components/aciacehighway/LocalTable'
import DialogForm from '../../components/aciacehighway/DialogForm'
import AlertDialog from '../../components/aciacehighway/AlertDialog'

const {
  form: {
    field: {
      CngTextField,
      CngCountryAutocompleteField,
      CngSwitchField
    },
  },
   CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  acctId:"",
  productidn: "",
  productDesc1: "",
  productDesc2: "",
  productDesc3: "",
  productDesc4: "",
  productDesc5: "",
  productDesc6: "",
  productDesc7: "",
  productDesc8: "",
  productDesc9: "",
  hsCode: "",
  hsCodeDesc: "",
  countryOfOrigin: "",
  active: "true",
  partyId: "",
  marksAndNumbers: "",
  undgs: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields(props) {
  const { disabled, isView, showNotification, shouldHideMap } = props

  const [formDialog, setFormDialog] = useState({ open: false, undg: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, undg: null })
  const { translate } = useTranslation(Namespace.PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  const undgs = watch('undgs')

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    let productMaster = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.TITLE
    )
    let productidn = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCTIDN
    )
    let acctId = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.ACCTID
    )
    let productDesc1 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC1
    )
    let productDesc2 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC2
    )
    let productDesc3 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC3
    )
    let productDesc4 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC4
    )
    let productDesc5 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC5
    )
    let productDesc6 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC6
    )
    let productDesc7 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC7
    )
    let productDesc8 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC8
    )
    let productDesc9 = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.PRODUCT_DESC9
    )
    let hsCode = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.HS_CODE
    )
    let hsCodeDesc = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.HS_CODE_DESC
    )
    let countryOfOrigin = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.COUNTRY_OF_ORIGIN
    )
    let active = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.ACTIVE
    )
    let marksAndNumbers = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.MARKS_AND_NUMBERS
    )

    let undgs = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.TITLE_IN_PRODUCT_MASTER
    )
    let undgId = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.UNDG_ID
    )
    let code = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.CODE
    )
    let description = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.DESCRIPTION
    )
    let intLCode = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.INTLCODE
    )
    let language = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.LANGUAGE
    )
    let indicator = translate(
      Namespace.PRODUCT_MASTER,
      ProductMasterKeys.INDICATOR
    )
    
    return {
      productMaster,
      acctId,
      productidn,
      productDesc1,
      productDesc2,
      productDesc3,
      productDesc4,
      productDesc5,
      productDesc6,
      productDesc7,
      productDesc8,
      productDesc9,
      hsCode,
      hsCodeDesc,
      countryOfOrigin,
      active,
      marksAndNumbers,
      undgs,
      undgId,
      code,
      description,
      intLCode,
      language,
      indicator
    }
  }

  function handleAddUNDG(data) {
    const undgs = [...getValues('undgs'), data]

    setValue('undgs', undgs)
    setFormDialog({ open: false, undg: null })
  }

  function handleDeleteUNDG() {
    if (!confirmDialog.undg) return

    const undgs = [...getValues('undgs')].filter(
      (undg) => undg._id !== confirmDialog.undg._id
    )

    setValue('undgs', undgs)
    setConfirmDialog({ open: false, undg: null })
  }

  function handleEditUNDG(data) {
    const undgs = [...getValues('undgs')]
    const index = undgs.findIndex((undg) => undg._id === data._id)

    undgs[index] = data

    setValue('undgs', undgs)
    setFormDialog({ open: false, undg: null })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection
            title={translatedTextsObject.productMaster}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>
                  *
                </Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.acctId}>
                <CngTextField
                  name='acctId'
                  inputProps={{ maxLength: 4 }}
                  label={translatedTextsObject.acctId}
                  isRequired
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productidn}>
                <CngTextField
                  name='productidn'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productidn}
                  isRequired
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc1}>
                <CngTextField
                  name='productDesc1'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc1}
                  isRequired
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc2}>
                <CngTextField
                  name='productDesc2'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc2}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc3}>
                <CngTextField
                  name='productDesc3'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc3}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc4}>
                <CngTextField
                  name='productDesc4'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc4}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc5}>
                <CngTextField
                  name='productDesc5'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc5}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc6}>
                <CngTextField
                  name='productDesc6'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc6}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc7}>
                <CngTextField
                  name='productDesc7'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc7}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc8}>
                <CngTextField
                  name='productDesc8'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc8}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc9}>
                <CngTextField
                  name='productDesc9'
                  inputProps={{ maxLength: 20 }}
                  label={translatedTextsObject.productDesc9}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hsCode}>
                <NaHSCodeAutocompleteField
                  name='hsCode'
                  label={translatedTextsObject.hsCode}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hsCodeDesc}>
                <CngTextField
                  name='hsCodeDesc'
                  inputProps={{ maxLength: 6 }}
                  label={translatedTextsObject.hsCodeDesc}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countryOfOrigin}>
                <CngCountryAutocompleteField
                  name='countryOfOrigin'
                  label={translatedTextsObject.countryOfOrigin}
                  disabled={disabled}
                  size='small'
                  lookupProps={{
                    label: (record) =>
                      [record.code, record.descriptionEn].join('; ')
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
                <CngSwitchField
                  name='active'
                  label={translatedTextsObject.active}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.marksAndNumbers}>
                <CngTextField
                  name='marksAndNumbers'
                  inputProps={{ maxLength: 35 }}
                  label={translatedTextsObject.marksAndNumbers}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <LocalTable
              columns={[
                {
                  field: 'code',
                  title: translatedTextsObject.code
                }
              ]}
              data={undgs}
              header={
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.undgs}
                </Typography>
              }
              {...(!isView && {
                actions: [
                  {
                    color: 'primary',
                    buttonProps: { color: 'secondary' },
                    label: 'Add UNDG',
                    icon: ['fal', 'plus-circle'],
                    onClick: () =>
                      setFormDialog({ open: true, undg: null })
                  }
                ],
                rowActions: [
                  {
                    icon: ['fal', 'pen'],
                    onClick: (rowData) =>
                      setFormDialog({ open: true, undg: rowData }),
                    tooltip: 'Edit'
                  },
                  {
                    icon: ['fal', 'trash'],
                    onClick: (rowData) =>
                      setConfirmDialog({ open: true, undg: rowData }),
                    tooltip: 'Delete'
                  }
                ]
              })}
            />
          </Card>
        </Grid>
      </Grid>
      <DialogForm
        formProperties={UndgsFormProperties}
        fullWidth
        isView={isView}
        maxWidth='md'
        open={formDialog.open}
        onClose={() => setFormDialog({ open: false, undg: null })}
        onSubmit={formDialog.undg ? handleEditUNDG : handleAddUNDG}
        title={`${formDialog.undg ? 'Edit' : 'Add New'} UNDG`}
        value={formDialog.undg}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, undg: null })}
        onCancel={() => setConfirmDialog({ open: false, undg: null })}
        onConfirm={handleDeleteUNDG}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.undgs.forEach((undg, index) => {
    undg['_id'] = index
  })
  
  return localData
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
