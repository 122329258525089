import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
 
  return Yup.object({

    acctId: Yup.string().required(requiredMessage).min(4,"Min length is 4").matches(/^[a-zA-Z0-9]+$/,"Cannot contain special characters like ),(,@ etc."),
    productidn: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc1: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc2: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc3: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc4: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc5: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc6: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc7: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc8: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    productDesc9: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    hsCode: Yup.string().nullable(),
    hsCodeDesc: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    countryOfOrigin: Yup.string().nullable(),
  })
}

export default makeValidationSchema
